<template lang="pug">
div.select-options-inner-wrapper.d-flex.justify-content-center
  div.select-options(v-show="!isTradeIn" )
    div.select-options-item.select-options-item--trade-in
      .check-text-title
        span {{ tradeIn.trade_in_btn }}
      label.check-label.trade-check(
        for="trade-yes"
      )
        input#trade-yes.custom-check-input(
          type="radio"
          name="istrade"
          :value="true"
          v-model="isTradeIn"
          @change="tradeInputChange"
        )
        .check
          Check

    div.select-options-item.select-options-item--trade-in
      .check-text-title
        span {{ tradeIn.no_trade_in_btn }}
      label.check-label.trade-check(
        for="trade-no"
      )
        input#trade-no.custom-check-input(
          type="radio"
          name="istrade"
          :value="false"
          v-model="isTradeIn"
          @change="tradeInputChange"
        )
        .check
          Check

  .trade-in-form(v-show="isTradeIn && !isTradeInSuccess")
    CloseCircle(@click="closeTradeInForm")
    .trade-in-form-title.text-center Jag önskar byta in min nuvarande bil
    .d-flex.justify-content-between.text-center
      .trade-in-form-input-wrapper
        label(for="registration_number") {{ tradeIn.registration_number }}
          the-mask#registration_number.form-control(
            type="text"
            :name="tradeIn.registration_number"
            :data-vv-name="tradeIn.registration_number"
            :class="{'is-invalid': errors.has('registration_number')}"
            v-model="trade.registration_number"
            v-validate="'min:6|max:6'"
            mask="AAA-XXX"
          )

      .trade-in-form-input-wrapper
        label(for="mileage") {{ tradeIn.mileage }}
          input#mileage.form-control(
            type="text"
            v-model="trade.mileage"
            v-validate="'required'"
            data-vv-name="mileage"
            :class="{'is-invalid':errors.has('mileage')}"
          )

        span.text-danger {{ errors[0] }}

      .trade-in-form-input-wrapper
        label(for="tradevalue") {{ tradeIn.value }}
          input#tradevalue.form-control(
            type="text"
            v-model="trade.value"
            v-validate="'required'"
            data-vv-name="tradevalue"
            :class="{'is-invalid':errors.has('tradevalue')}"
          )

        span.text-danger {{ errors[0] }}
    .row
      .col-md-12.m-t-20
        .form-group
          label(for="trade_comments") {{ tradeIn.comments }}
          textarea#trade_comments.no-resize.resize-vertical.form-control(
            rows="2"
            v-model="trade.comments"
          )

    .row.m-t-10
      .col-md-3.offset-md-9
        button.btn-hover.btn.btn-og.step-btn(:disabled="errors.has('mileage') || errors.has('tradevalue')" @click.prevent="saveTrade") Spara

  .m-t-10.m-b-10(v-show="isTradeInSuccess")
    .step-title {{ tradeIn.confirmation_text }}

</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'Trades',
  components: {
    Check: () => import('@/components/svg/check'),
    CloseCircle: () => import('@/components/steps-faceless/CloseCircle.vue'),
  },
  data: () => ({
    trade: {
      registration_number: '',
      mileage: '',
      value: '',
      comments: '',
    },
    isTradeInSuccess: false,
  }),
  mounted() {
    this.isTradeIn = false
  },
  computed: {
    ...mapState('reseller', {
      tradeIn: (state) => state.resellerInfo.settings?.tradeIn || {}
    }),
    isTradeIn: {
      get() {
        return this.$store.state.order.isTradeIn
      },
      set(value) {
        this.$store.commit('order/setIsTradeIn', value)
      },
    },
  },
  watch: {
    'trade.value'(val) {
      this.trade.value = val
        .toString()
        .replace(/ /g, '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    'trade.mileage'(val) {
      this.trade.mileage = val
        .toString()
        .replace(/ /g, '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
  },
  methods: {
    ...mapMutations('global', ['disableButton', 'enableButton']),
    ...mapMutations('order', ['setTradeIn']),
    closeTradeInForm() {
      this.$store.commit('order/setIsTradeIn', false)
    },
    async saveTrade() {
      if (!(this.trade.mileage && this.trade.value)) return

      this.setTradeIn({
        registration_number: this.trade.registration_number,
        mileage: this.trade.mileage.replace(/ /g, ''),
        value: this.trade.value.replace(/ /g, ''),
        comments: this.trade.comments,
      });
      this.enableButton()

      this.isTradeInSuccess = true
    },
    tradeInputChange() {
      if (!this.isTradeIn) {
        this.enableButton()

        return
      }

      if (!this.trade.registration_number || !this.trade.mileage)
        this.disableButton()
    },
  },
}
</script>

<style lang="sass">
label.custom-check.trade-check
  display: flex
  align-items: center
  margin-right: 20px 
</style>
